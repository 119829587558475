import { ILangStr, TLang } from '../../types/lang';
import {EQuestionType} from "../assessments/types";

export interface TPackage {
  id: string;
  name: string;
  description: string;
  workspace_id: string;
  link_created_at: Date;
  updated_at: Date;
  total_number_of_questions: number;
  is_scheduled: boolean;
  is_paused: boolean;
  is_forever: boolean;
  link_expiration: number;
  languages_tags: TLang[];
  delayed_message: TDelayedMessage[];
  is_has_delayed_message: boolean;
  min_number_of_days: number;
  count_sent_unique_emails: {
    notified: number;
    surveyed: number;
  };
  assessments: {
    id: string;
    name: string | ILangStr;
    category_id: string;
    category_name: string;
    answer_type: EQuestionType;
    version: string;
    number_of_questions: number;
    relation_created_at: string;
  }[];
}

export enum ETypeOfLinkBeingSent {
  combinedLink = 'combined_link',
  publicLink = 'public_link'
}

export enum EMessageStatusType {
  expired = 'expired',
  registered = 'registered',
  triggered = 'triggered',
  sent = 'sent',
  failed = 'failed',
  draft = 'draft',
}

export enum ETypeOfMessageType {
  schedule = 'schedule',
  trigger = 'trigger',
  not_selected = 'not_selected'
}

export type TTypeOfMessage =
    | ETypeOfMessageType.schedule
    | ETypeOfMessageType.trigger
    | ETypeOfMessageType.not_selected;

export type TMessageStatus =
    | EMessageStatusType.expired
    | EMessageStatusType.registered
    | EMessageStatusType.triggered
    | EMessageStatusType.sent
    | EMessageStatusType.failed
    | EMessageStatusType.draft;

export interface TDelayedMessagesGroups {
  id: string;
  group: {
    id: string;
    name: string;
    created_at: string;
  };
}

export interface ClosedPackageLinksWithTextQuestionListResp {
  id: string;
  name: string;
  link:string;
  link_expiration: number;
  link_created_at: Date;
}

export interface TDelayedMessage {
  delayed_messages_to_groups: TDelayedMessagesGroups[];
  id: string;
  metadata: {
    email_content: string;
    email_subject: string;
  };
  email_message: string;
  email_signature: string;
  package_id: string;
  send_at: string;
  status: TMessageStatus;
  type_of_link_being_sent: ETypeOfLinkBeingSent;
  type_of_message: TTypeOfMessage;
  trigger_days_after: number;
  team_id: string;
  workspace_id: string;
}

export interface TSendNowResponse {
  delayed_message: TDelayedMessage;
  emails_sent_number: number;
  requests_errors: string[];
}

export interface TPackagesState {
  readonly loading: boolean;
  data: TPackage[] | null;
  current: TPackage | null;
  editObj: TPackage | null;
  readonly errors?: string | undefined;
}

enum ActionTypes {
  GET_ALL_R = '@@packages/GET_ALL_R',
  GET_ALL_S = '@@packages/GET_ALL_S',
  GET_ALL_E = '@@packages/GET_ALL_E',

  CREATE_R = '@@packages/CREATE_R',
  CREATE_S = '@@packages/CREATE_S',
  CREATE_E = '@@packages/CREATE_E',

  DELETE_R = '@@packages/DELETE_R',
  DELETE_S = '@@packages/DELETE_S',
  DELETE_E = '@@packages/DELETE_E',

  UPDATE_ASS = '@@packages/UPDATE_ASS',

  EDIT_R = '@@packages/EDIT_R',
  EDIT_S = '@@packages/EDIT_S',
  EDIT_E = '@@packages/EDIT_E',

  SET_EDIT_MODE = '@@packages/SET_EDIT_MODE',

  SET_PACK = '@@packages/SET_PACK',

  CLEAN_UP = '@@packages/CLEAN_UP',
}

export default ActionTypes;
