import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {AppStore} from "../store/applicationState";
import {DefaultSelect, SelectItemType} from "../components/ui/selects/DefaultSelect";
import {useAiGenerate} from "../hooks/useAiGenerate";
import {TEXT} from "../utils/textConst";
import {callApi} from "../utils/callApi";
import {API_ROUTE_PATH} from "../utils/api_routes";
import {Alert, Loader} from "../components/ui";
import {AssessmentIcon} from "../components/svgs";
import {ButtonBorderedS, ButtonDefS} from "../components/ui/buttons/styles";
import {MainWrapperS} from "../globalStyle";
import {PageTitleWrapperS} from "../components/RightSideBar/RightSideBarStyles";
import styled from "styled-components";
import {COLORS} from "../utils/colors";
import {AI} from "../components/svgs/AI";
import {InfoOfProcessingModal} from "../components/modals/InfoOfProcessingModal";
import {InfoAIModal} from "../components/modals/InfoAIModal";

interface AiGenerateProps {}

let mapOfPeriods = new Map<string, {
    link: string;
    start_period: Date;
    end_period: Date;
}[]>();

function addPeriod(key: string, link: string, startPeriod: Date, endPeriod: Date) {

    if (!mapOfPeriods.has(key)) {
        mapOfPeriods.set(key, []);
    }

    const periods = mapOfPeriods.get(key);

    if (periods) {
        periods.push({ link, start_period: startPeriod, end_period: endPeriod });
    }
}

export const AiGenerate: React.FC<AiGenerateProps> = () => {
    const { Cats, Workspaces } = useSelector((store: AppStore) => store);
    const [isInfoOfProcessing, setIsInfoOfProcessing] = React.useState(false);
    const [isInfo, setIsInfo] = React.useState(false);
    const [isSelectedCycle, setIsSelectedCycle] = React.useState(false);
    const [errorText, setErrorText] = React.useState('');
    const [selectPackage, setSelectPackage] = React.useState<SelectItemType[]>([]);
    const [selectCycle, setSelectCycle] = React.useState<SelectItemType[]>([]);
    const [isLoadingPackage, setIsLoadingPackage] = React.useState(true);
    // const [isLoadingCycle, setIsLoadingCycle] = React.useState(true);
    const [step, setStep] = React.useState(1);
    const dispatch = useDispatch();
    const {
        selectedPackage,
        selectedCycle,
        setSelectedCycle,
        setSelectedPackage,
        isLoading
    } = useAiGenerate();

    React.useEffect(() => {
        const callFn = async () => {
            if (!Workspaces.current!.id)
                return setErrorText('No Workspaces id, ' + TEXT.tryLater);
            try {

                setSelectPackage(null);
                setSelectedPackage(null)
                setSelectCycle(null);
                setSelectedCycle(null);
                setIsLoadingPackage(true);
                setStep(1)
                const resp = (await callApi({
                    path: `${API_ROUTE_PATH.openAi.getClosedPackageAndCyclesWithTextAnswersByWorkspaceID(
                        Workspaces.current!.id,
                    )}`,
                    method: 'get',
                })) as {
                    id: string;
                    name: string;
                    period_data: {
                        link: string;
                        start_period: Date;
                        end_period: Date;
                        }[],
                }[];
                if (!Array.isArray(resp))
                    return setErrorText('Wrang format API data, ' + TEXT.tryLater);
                const newSelectData: SelectItemType[] = [];

                resp.forEach((itm) => {
                    let name = itm.name;
                    if (
                        resp.find(
                            (pkg) => pkg.name === itm.name && pkg.id !== itm.id
                        )
                    )
                        name = `${itm.name}`;
                    newSelectData.push({
                        title: name,
                        value: itm.id,
                        id: itm.id,
                    });


                    itm.period_data.forEach((dat) => {
                       addPeriod(itm.id, dat.link, dat.start_period, dat.end_period)
                    })

                });

                console.log(selectPackage)
                setSelectPackage(newSelectData);
            } catch (error) {
                setErrorText(`${error}`);
            } finally {
                setIsLoadingPackage(false);
            }
        };

        if (Workspaces.current) {
            callFn();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [Workspaces.current, dispatch]);

    const callCycles = (item:  SelectItemType) => {
                setSelectedPackage(item)
                setSelectedCycle(null)
                setStep(2)
        let periods = mapOfPeriods.get(item.id as string)
        const newCycles: SelectItemType[] = [];
                var links: string[] = [];
        periods.forEach((itm) => {
                    const dateFrom = new Date(itm.start_period);
                    const dateTo = new Date(itm.end_period);
                    const dateNow = new Date();
                    const dateNull = new Date('0001-01-01T00:00:00Z');

                    const dateFromString = `${dateFrom.getMonth()+1}/${dateFrom.getDate()}/${dateFrom.getFullYear()}`
                    const dateToString = `${dateTo.getMonth()+1}/${dateTo.getDate()}/${dateTo.getFullYear()}`

                    let dateString = dateFromString

                    // if ((dateNow < dateTo) || (dateTo.getTime() === dateNull.getTime() && itm.is_forever_package)) {
                    //     dateString += ' to current'
                    // }
                    if (dateNow > dateTo && (dateTo.getTime() !== dateNull.getTime())) {
                        dateString += ' to ' + dateToString;
                    }

                    newCycles.push({
                        title: dateString,
                        value: itm.link,
                        id: itm.link,
                    });
                    links.push(itm.link)
                });
        setSelectCycle(newCycles);
    };

    const createWorkers = async () => {
            if (!Workspaces.current!.id)
                return setErrorText('No Workspaces id, ' + TEXT.tryLater);
        try {
            const resp = (await callApi({
                method: 'post',
                path: API_ROUTE_PATH.openAi.createWorker(Workspaces.current.id),
                data: {
                    workspace_id: Workspaces.current.id,
                    package_id: selectedPackage.id,
                    package_link: selectedCycle.id
                },
            })) as boolean

            if (!resp)
                return setErrorText('Wrong format API data, ' + TEXT.tryLater);

        } catch (error) {
            setErrorText(`${error}`);
        } finally {
            setIsInfo(true)
        }
    }
    // const callCycles = async (item:  SelectItemType) => {
    //     if (!Workspaces.current!.id)
    //         return setErrorText('No Workspaces id, ' + TEXT.tryLater);
    //     if (!item?.id)
    //         return setErrorText('No Package id, ' + TEXT.tryLater);
    //     try {
    //         setIsLoadingCycle(true);
    //         setSelectedPackage(item)
    //         setSelectedCycle(null)
    //         setStep(2)
    //         const resp = (await callApi({
    //             path: `${API_ROUTE_PATH.packages.getAnsweredCyclesByWorkspace(
    //                 Workspaces.current!.id,
    //                 item.id as string,
    //                 '00000000-0000-0000-0000-000000000000',
    //                 false
    //             )}`,
    //             method: 'get',
    //         })) as {
    //             from: string;
    //             to: string;
    //             is_forever_package: boolean;
    //             link: string;
    //         }[];
    //
    //         if (!Array.isArray(resp))
    //             return setErrorText('Wrong format API data, ' + TEXT.tryLater);
    //
    //         const newCycles: SelectItemType[] = [];
    //         var links: string[] = [];
    //         resp.forEach((itm) => {
    //             const dateFrom = new Date(itm.from);
    //             const dateTo = new Date(itm.to);
    //             const dateNow = new Date();
    //             const dateNull = new Date('0001-01-01T00:00:00Z');
    //
    //             const dateFromString = `${dateFrom.getMonth()+1}/${dateFrom.getDate()}/${dateFrom.getFullYear()}`
    //             const dateToString = `${dateTo.getMonth()+1}/${dateTo.getDate()}/${dateTo.getFullYear()}`
    //
    //             let dateString = dateFromString
    //
    //             if ((dateNow < dateTo) || (dateTo.getTime() === dateNull.getTime() && itm.is_forever_package)) {
    //                 dateString += ' to current'
    //             }
    //             if (dateNow > dateTo && (dateTo.getTime() !== dateNull.getTime() && !itm.is_forever_package)) {
    //                 dateString += ' to ' + dateToString;
    //             }
    //
    //             newCycles.push({
    //                 title: dateString,
    //                 value: itm.link,
    //                 id: itm.link,
    //             });
    //             links.push(itm.link)
    //         });
    //
    //         setSelectCycle(newCycles);
    //     } catch (error) {
    //         setErrorText(`${error}`);
    //     } finally {
    //         setIsLoadingCycle(false);
    //     }
    // };

    const render = () => {
        if (errorText) {
            return (
                <div style={{ marginTop: '20px' }}>
                    <Alert text={errorText} />
                </div>
            );
        }
        // if (isDataLoading) {
        //   return <Loader isGreen />;
        // }

        if (!Cats.data && !Cats.loading) {
            return (
                <div style={{ marginTop: '20px' }}>
                    <Alert text="No assessments" type="warning" />
                </div>
            );
        }
        return (
            <div style={{ position: 'relative' }}>
                <SelectWrapperS>
                    {step >= 1&&
                        <DefaultSelect
                            data={selectPackage}
                            icon={AssessmentIcon}
                            selected={selectedPackage}
                            label="Choose Package"
                            onChange={(e) => {
                                callCycles(e)
                            }}
                            placeHolder="Choose Package"
                            isLoading={isLoadingPackage}
                        />
                    }
                    {step >= 2&&
                        <DefaultSelect
                            data={selectCycle}
                            label="Choose Cycle"
                            placeHolder="Choose Cycle"
                            icon={AssessmentIcon}
                            selected={ selectedCycle }
                            onChange={(item: SelectItemType) => {
                                setIsSelectedCycle(true)
                                setSelectedCycle(item)
                            }}
                        />
                    }
                </SelectWrapperS>
                <WrapperButtonS>
                    <ButtonDefS
                        onClick={() => createWorkers()}
                        style={{ maxWidth: '97px', marginRight: '16px'}}
                        disabled={!isSelectedCycle}
                    >
                        {isLoading ? <Loader size={0.5} /> : 'Generate'}
                    </ButtonDefS>
                    <ButtonBorderedS
                        onClick={() => setIsInfoOfProcessing(true)}
                        style={{ maxWidth: '170px' }}
                    >
                        {isLoading ? <Loader size={0.5} /> : 'Info of processing'}
                    </ButtonBorderedS>
                </WrapperButtonS>
            </div>
        );
    };

    return (
        <>
            <MainWrapperS>
                <PageTitleWrapperS>
                    <h1>AI Generate</h1>
                </PageTitleWrapperS>
                <WrapperTitleIconS>
                    <AI />
                    <h3>Generate Data</h3>
                    <InfoIS>
                        i
                        <div>
                            <div>
                                To load the file with the group report, please select the package (from the list of packages with answers), and then select the cycle from the list of cycles with answers (in the dropdown you can see the start dates of the cycles for choosing). Select the group types if you prefer to have only some of them on separate pages. </div>
                        </div>
                    </InfoIS>
                </WrapperTitleIconS>
                {render()}
            </MainWrapperS>
            {!isInfoOfProcessing ? null : (
                <InfoOfProcessingModal
                    closeHandler={() =>{
                        setIsInfoOfProcessing(false)
                    }}
                />
            )}
            {!isInfo ? null : (
                <InfoAIModal
                    closeHandler={() =>{
                        setIsInfo(false)
                    }}
                />
            )}
        </>
    );
};

const WrapperButtonS = styled.div`
  display: flex;
  flex-direction: row;
`;

const InfoIS = styled.div`
  font-size: 12px;
  position: relative;
  left: 10px;
  top: 8px;
  transform: translateY(-50%);
  width: 16px;
  height: 16px;
  background: ${COLORS.accent};
  color: white;
  text-align: center;
  margin-left: 2px;
  border-radius: 10px;
  z-index: 3;

  & > div {
    background-color: white;
    font-weight: normal;
    font-size: 14px;
    display: none;
    position: absolute;
    top: 200%;
    width: 250px;
    left: -75px;
    border-radius: 10px;
    box-shadow: 0 11px 33px -9px rgba(42, 47, 42, 0.23);
    border: solid 1px rgba(162, 165, 162, 0.15);
    color: ${COLORS.default};
    text-align: left;
    padding: 15px;
  }

  &:hover {
    & > div {
      display: block;
    }
  }
`;

const WrapperTitleIconS = styled.div`
  display: flex;
  align-items: center;
  h3 {
    font-size: 1.6rem;
    margin: 0 0 0 10px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #171717;
  }
`;

const SelectWrapperS = styled.div`
  width: 100%;
  margin: 24px 0;
  max-width: 328px;

  & > * {
    margin-bottom: 15px;
  }

  .svgIconSelect {
    opacity: 0.3;
  }
`;

