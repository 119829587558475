import React from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { AreYouShurePopUp } from '../components';
import { PageTitleWrapperS } from '../components/RightSideBar/RightSideBarStyles';
import {AssessmentIcon, IdentityIcon} from '../components/svgs';
import { Alert, DefaultSelect, Loader } from '../components/ui';
import { ButtonDefS } from '../components/ui/buttons/styles';
import { SelectItemType } from '../components/ui/selects/DefaultSelect';
import { MainWrapperS } from '../globalStyle';
import { useCSV } from '../hooks/useCsv';
import { AppStore } from '../store/applicationState';
import { API_ROUTE_PATH } from '../utils/api_routes';
import { callApi } from '../utils/callApi';
import { TEXT } from '../utils/textConst';
import {ALL_CYCLES, ALL_GROUPS, ALL_GROUPS_TYPES, ALL_PARTICIPANTS} from "../utils/consts";
import {COLORS} from "../utils/colors";
import {XlsxIcon} from "../components/svgs/XlsxIcon";
import {deepClone} from "../utils/deepClone";
import {ParticipantSources} from "../store/groups/types";
import {OriginSourceGroupSelect} from "../components/ui/selects/OriginSourceGroupSelect";
import {HashTableActiveGroup, IsActiveObject} from "../components/Analytics/useCharts";
import {HashTable} from "../components/CsvAddParticipants/useDropFile";
interface AiReportProps {}

let mapOfPeriods = new Map<string, {
    start_period: Date;
    end_period: Date;
}>();

function addPeriod(key_link: string, startPeriod: Date, endPeriod: Date) {
    if (!mapOfPeriods.has(key_link)) {
        mapOfPeriods.set(key_link, { start_period: startPeriod, end_period: endPeriod });
    }
}

export const AiReport: React.FC<AiReportProps> = () => {
    const { Cats, Workspaces } = useSelector((store: AppStore) => store);
    const [errorText, setErrorText] = React.useState('');
    const [activeOriginSource, setActiveOriginSource] = React.useState<IsActiveObject[]>([])
    const [activeGroups, setActiveGroups] = React.useState<HashTableActiveGroup>({})
    const [selectPackage, setSelectPackage] = React.useState<SelectItemType[]>([]);
    const [selectDataOriginSource, setSelectDataOriginSource] = React.useState<
        SelectItemType[]
    >([]);
    const [selectedCycle, setSelectedCycle] = React.useState<SelectItemType | null>(null);
    const [selectAssessment, setSelectAssessment] = React.useState<SelectItemType[]>([]);
    // const [isLoadingOriginSource, setIsLoadingOriginSource] = React.useState(true);
    const [isLoadingPackage, setIsLoadingPackage] = React.useState(true);
    const [isLoadingAss, setIsLoadingAss] = React.useState(true);
    const [isLoadingCycle, setIsLoadingCycle] = React.useState(true);
    const [step, setStep] = React.useState(1);
    const dispatch = useDispatch();

    // const [isOpenGroupsSelect, setIsOpenGroupsSelect] = React.useState(false)
    const openHandler = (isOpen: boolean) => {
        // setIsOpenGroupsSelect(isOpen)
    }

    const {
        setSelectedOriginSource,
        selectedPackage,
        selectedAssessment,
        setSelectedCycles,
        setSelectedPackage,
        setSelectedAssessment,
        cycleSelectHandler,
        setSelectCycles,
        selectCycles,
        activeCyclesLinks,
        genCSV,
        isLoading,
        tryMask,
        popUpYesHandler,
        popUpNoHandler,
    } = useCSV();

    React.useEffect(() => {
        const callFn = async () => {
            if (!Workspaces.current!.id)
                return setErrorText('No Workspaces id, ' + TEXT.tryLater);
            try {
                setSelectPackage(null);
                setSelectedPackage(null)
                setSelectAssessment(null);
                setSelectedAssessment(null);
                setSelectCycles([]);
                setSelectedCycles([]);
                setIsLoadingPackage(true);
                setStep(1)
                const resp = (await callApi({
                    path: `${API_ROUTE_PATH.openAi.getGeneratedDatePackagesByWorkspaceID(
                        Workspaces.current!.id,
                    )}`,
                    method: 'get',
                })) as {
                    id: string;
                    name: string;
                    is_forever: boolean
                }[];
                if (!Array.isArray(resp))
                    return setErrorText('Wrang format API data, ' + TEXT.tryLater);
                const newSelectData: SelectItemType[] = [];
                resp.forEach((itm) => {
                    let name = itm.name;
                    if (
                        resp.find(
                            (pkg) => pkg.name === itm.name && pkg.id !== itm.id
                        )
                    )
                        name = `${itm.name}`;
                    newSelectData.push({
                        title: name,
                        value: itm.id,
                        id: itm.id,
                    });
                });

                setSelectPackage(newSelectData);
            } catch (error) {
                setErrorText(`${error}`);
            } finally {
                setIsLoadingPackage(false);
            }
        };

        if (Workspaces.current) {
            callFn();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [Workspaces.current, dispatch]);

    const setResetGroups = (dataGroup:  HashTableActiveGroup, key: string, value: boolean) => {
        if (dataGroup.hasOwnProperty(key)) {
            dataGroup[key]?.length > 0 && dataGroup[key].forEach((obj, idx) => {
                if (idx === 0 || key === 'default') {
                    dataGroup[key][idx].is_active = value
                } else {
                    dataGroup[key][idx].is_active = false
                }

            })
        }
    }

    const setAllValueOriginSource = (dataGroup:  HashTableActiveGroup, dataOriginSource: IsActiveObject[], value: boolean) => {
        dataOriginSource?.length > 0 && dataOriginSource.forEach((itm, idx) => {
            if (itm.id !== ALL_PARTICIPANTS.id) {
                dataOriginSource[idx].is_active = value
                setResetGroups(dataGroup, itm.id, value)
            } else {
                dataOriginSource[idx].is_active = !value
            }
        })
    }

    const setOnceValueOriginSource = (dataGroup:  HashTableActiveGroup, dataOriginSource: IsActiveObject[], originSource: string) => {
        const idx = dataOriginSource.findIndex((it) => it.id === originSource)
        if (idx >= 0) {
            dataOriginSource[idx].is_active = !dataOriginSource[idx].is_active
            setResetGroups(dataGroup, dataOriginSource[idx].id, dataOriginSource[idx].is_active)
            dataOriginSource[0].is_active = checkAllParticipants(dataGroup)
            dataOriginSource[1].is_active = checkAllGroups(dataGroup)
        }
    }

    const setAllValueGroup = (dataGroup:  HashTableActiveGroup, dataOriginSource: IsActiveObject[], originSource: string, value: boolean) => {
        dataGroup[originSource].forEach((itm, idx) => {
            if (idx === 0) {
                dataGroup[originSource][idx].is_active = value
            } else {
                dataGroup[originSource][idx].is_active = !value
            }
        })

        dataOriginSource[1].is_active = checkAllGroups(dataGroup)
    }

    const setOnceValueGroup = (dataGroup:  HashTableActiveGroup, dataOriginSource: IsActiveObject[], originSource: string, groupId: string) => {
        if (dataGroup[originSource]?.length < 3 && originSource !== 'default')
            return

        if (dataGroup[originSource][0].is_active) {
            dataGroup[originSource][0].is_active = false
        }

        const idx = dataGroup[originSource].findIndex((it) => it.id === groupId)
        if (idx >= 0) {
            dataGroup[originSource][idx].is_active = !dataGroup[originSource][idx].is_active

            if (!dataGroup[originSource][idx].is_active) {
                dataGroup[originSource][0].is_active = dataGroup[originSource][idx].is_active
            }


            const idxForAllGroupsFalse = dataGroup[originSource].findIndex((it, i) => i !== 0 && it.is_active === false )

            if (idxForAllGroupsFalse === -1 && originSource !== 'default') {
                dataGroup[originSource].forEach((itm, i) => {
                    if (i === 0) {
                        dataGroup[originSource][i].is_active = true
                    } else {
                        dataGroup[originSource][i].is_active = false
                    }
                })
            }

            const idxForAllGroupsTrue = dataGroup[originSource].findIndex((it, i) => i !== 0 && it.is_active === true && originSource !== 'default')

            if (idxForAllGroupsTrue === -1 && originSource !== 'default') {
                dataGroup[originSource].forEach((itm, i) => {
                    if (i === 0) {
                        dataGroup[originSource][i].is_active = true
                    } else {
                        dataGroup[originSource][i].is_active = false
                    }
                })
            }

            dataOriginSource[0].is_active = checkAllParticipants(dataGroup)
            dataOriginSource[1].is_active = checkAllGroups(dataGroup)
        }
    }

    const checkAllParticipants = (dataGroup:  HashTableActiveGroup): boolean => {
        for (const key in dataGroup) {
            if (dataGroup.hasOwnProperty(key)) {
                const idx = dataGroup[key].findIndex((it) => it.is_active === true)
                if (idx >= 0)
                    return !dataGroup[key][idx].is_active
            }
        }
        return true
    }

    const checkAllGroups = (dataGroup:  HashTableActiveGroup): boolean => {
        for (const key in dataGroup) {
            if (dataGroup.hasOwnProperty(key)) {
                if (!dataGroup[key][0].is_active)
                    return dataGroup[key][0].is_active
            }
        }
        return true
    }

    const callGroups = async (item: SelectItemType) => {
        if (!Workspaces.current!.id)
            return setErrorText('No Workspaces id, ' + TEXT.tryLater);
        if (!item?.id)
            return setErrorText('No select Origin Source, ' + TEXT.tryLater);

        try {
            // setIsLoadingOriginSource(true);
            setSelectedCycle(item)
            setSelectedOriginSource([ALL_GROUPS_TYPES])
            setStep(3)
            const resp = (await callApi({
                path: `${API_ROUTE_PATH.openAi.getGeneratedDateGroupsByWorkspaceID(
                    Workspaces.current!.id,
                    selectedPackage.id as string,
                    selectedAssessment.id as string,
                )}`,
                method: 'post',
                data: {
                    link: selectedCycle.id,
                    start_period: mapOfPeriods.get(selectedCycle.id as string).start_period,
                    end_period: mapOfPeriods.get(selectedCycle.id as string).end_period,
                    is_all: false
                }
            })) as {
                id: string,
                name: string,
                origin_source: string
            }[];

            if (!Array.isArray(resp))
                return setErrorText('Wrang format API data, ' + TEXT.tryLater);
            // const newSelectData: SelectItemType[] = [ALL_GROUPS_TYPES];
            // resp.origin_sources.forEach((itm) => {
            //     if (itm !== '') {
            //         newSelectData.push({
            //             title: ParticipantSources[itm],
            //             value: itm,
            //             id: itm,
            //         });
            //     }
            // });
            //
            // setSelectOriginSource(newSelectData);

            const setDataOriginSource = () => {
                if (!resp) return null;
                const newSelects: SelectItemType[] = [ALL_PARTICIPANTS];
                const newActiveGroups: HashTableActiveGroup = {}
                const newActiveOriginSource: IsActiveObject[]  = [{
                    id: ALL_PARTICIPANTS.id,
                    title: ALL_PARTICIPANTS.title,
                    is_active: true
                }];

                if (resp && resp[1]) {
                    newSelects.push(ALL_GROUPS);
                    newActiveOriginSource.push({
                        id: ALL_GROUPS.id,
                        title: ALL_GROUPS.title,
                        is_active: false
                    })
                }

                const hashTable: HashTable = {};

                const arrOfKeys: string[] = []

                resp.forEach((itm) => {
                    const os = itm.origin_source !== '' ? itm.origin_source : 'default'

                    if (!hashTable[os]) {
                        arrOfKeys.push(os)
                        hashTable[os] = []
                        newActiveGroups[os] = []

                        newActiveGroups[os].push({
                            id: ALL_GROUPS.id,
                            title: ALL_GROUPS.title,
                            is_active: false
                        })
                    }

                    // hashTable[os].push({
                    //   id: itm.id,
                    //   origin_source: os,
                    //   name: itm.name
                    // })

                    newActiveGroups[os].push({
                        id:itm.id,
                        title: itm.name,
                        is_active: false
                    })
                });

                arrOfKeys.sort()

                arrOfKeys.forEach((key, idx) => {
                    if (key !== 'default') {
                        newSelects.push({
                            id: key,
                            value: key,
                            title: ParticipantSources[key],
                            groups: hashTable[key],
                        })

                        newActiveOriginSource.push({
                            id: key,
                            title: ParticipantSources[key],
                            is_active: false
                        });
                    }

                    if (idx === arrOfKeys.length-1) {
                        newSelects.push({
                            id: 'default',
                            value: 'default',
                            title: ParticipantSources[''],
                            groups: hashTable['default'],
                        })

                        newActiveOriginSource.push({
                            id: 'default',
                            title: ParticipantSources[''],
                            is_active: false
                        });
                    }
                })

                setActiveGroups(newActiveGroups)
                setActiveOriginSource(newActiveOriginSource)
                setSelectDataOriginSource(newSelects);
            };

            if (resp && resp[0]) {
                setDataOriginSource();
            } else if (!resp || !resp[0]) {
                const newSelects: SelectItemType[] = [ALL_PARTICIPANTS];
                setSelectDataOriginSource(newSelects);
            }
        } catch (error) {
            setErrorText(`${error}`);
        } finally {
            // setIsLoadingOriginSource(false);
        }
    }

    const originSourceSelectHandler = (data: SelectItemType) => {
        if (
            data.id === ALL_PARTICIPANTS.id &&
            activeOriginSource[0].is_active
        ) {
            return null;
        }
        if (
            data.id === ALL_GROUPS.id &&
            activeOriginSource[1].is_active
        ) {
            return null;
        }

        const newActiveOriginSource = deepClone(activeOriginSource);
        const newActiveGroups = deepClone(activeGroups);

        switch (data.id) {
            case ALL_PARTICIPANTS.id:
                setAllValueOriginSource(newActiveGroups, newActiveOriginSource, false)
                setActiveOriginSource(newActiveOriginSource)
                setActiveGroups(newActiveGroups)
                break
            case ALL_GROUPS.id:
                setAllValueOriginSource(newActiveGroups, newActiveOriginSource, true)
                setActiveOriginSource(newActiveOriginSource)
                setActiveGroups(newActiveGroups)
                break
            default:
                setOnceValueOriginSource(newActiveGroups, newActiveOriginSource, data.id as string)
                setActiveOriginSource(newActiveOriginSource)
                setActiveGroups(newActiveGroups)
        }
    }

    const groupSelectHandler = (data: SelectItemType, parentId: string) => {
        if (
            data.id === ALL_GROUPS.id &&
            activeGroups[parentId][0].is_active
        ) {
            return null;
        }

        const newActiveOriginSource = deepClone(activeOriginSource);
        const newActiveGroups = deepClone(activeGroups);

        switch (data.id) {
            case ALL_GROUPS.id:
                setAllValueGroup(newActiveGroups, newActiveOriginSource, parentId, true)
                setActiveOriginSource(newActiveOriginSource)
                setActiveGroups(newActiveGroups)
                break
            default:
                setOnceValueGroup(newActiveGroups, newActiveOriginSource, parentId, data.id as string)
                setActiveOriginSource(newActiveOriginSource)
                setActiveGroups(newActiveGroups)
        }
    }

    const callAss = async (itm:  SelectItemType) => {
        if (!Workspaces.current!.id)
            return setErrorText('No Workspaces id, ' + TEXT.tryLater);
        if (!itm?.id)
            return setErrorText('No Package id, ' + TEXT.tryLater);
        try {
            setSelectAssessment(null);
            setSelectedAssessment(null);
            setSelectCycles([]);
            setIsLoadingAss(true);
            setSelectedPackage(itm)
            setStep(2)
            const resp = (await callApi({
                path: `${API_ROUTE_PATH.openAi.getGeneratedDateAssessmentsByWorkspaceID(
                    Workspaces.current!.id,
                    selectedPackage.id as string,
                )}`,
                method: 'get',
            })) as {
                id: string;
                name: string;
                // is_archived: boolean;
                // version: string;
            }[];
            if (!Array.isArray(resp))
                return setErrorText('Wrang format API data, ' + TEXT.tryLater);
            const newSelectData: SelectItemType[] = [];
            resp.forEach((itm) => {
                if (
                    // resp.find(
                    //     (ass) => ass.name === itm.name && ass.id !== itm.id && ass.version
                    // )
                    resp.find(
                        (ass) => ass.name === itm.name && ass.id !== itm.id
                    )
                )
                //     name = `${itm.name} ${itm.version}`;
                // newSelectData.push({
                //     title:  !itm.is_archived ? name : name + PREF,
                //     value: itm.id,
                //     id: itm.id,

                newSelectData.push({
                    title:  itm.name,
                    value: itm.id,
                    id: itm.id,
                });
            });

            setSelectAssessment(newSelectData);
        } catch (error) {
            setErrorText(`${error}`);
        } finally {
            setIsLoadingAss(false);
        }
    };

    const callCycles = async (item:  SelectItemType) => {
        if (!Workspaces.current!.id)
            return setErrorText('No Workspaces id, ' + TEXT.tryLater);
        if (!item)
            return setErrorText('No selected items, ' + TEXT.tryLater);
        try {
            setIsLoadingCycle(true);
            setSelectedAssessment(item)
            setStep(3)
            const resp = (await callApi({
                path: `${API_ROUTE_PATH.openAi.getGeneratedDatePeriodsByWorkspaceID(
                    Workspaces.current!.id,
                    selectedPackage.id as string,
                    selectedAssessment.id as string,
                )}`,
                method: 'get',
            })) as {
                period_start: string;
                period_end: string;
                is_forever_package: boolean;
                link: string;
            }[];
            if (!Array.isArray(resp))
                return setErrorText('Wrang format API data, ' + TEXT.tryLater);

            const newCycles: typeof selectCycles = [ALL_CYCLES];
            var links: string[] = [];
            resp.forEach((itm) => {
                const dateFrom = new Date(itm.period_start);
                const dateTo = new Date(itm.period_end);

                const dateFromString = `${dateFrom.getMonth()+1}/${dateFrom.getDate()}/${dateFrom.getFullYear()}`
                const dateToString = `${dateTo.getMonth()+1}/${dateTo.getDate()}/${dateTo.getFullYear()}`

                let dateString = dateFromString

                dateString += ' to ' + dateToString;

                addPeriod(itm.link, dateFrom, dateTo)

                newCycles.push({
                    title: dateString,
                    value: itm.link,
                    id: itm.link,
                });
                links.push(itm.link)
            });

            setSelectedCycles(links)
            setSelectCycles(newCycles);
        } catch (error) {
            setErrorText(`${error}`);
        } finally {
            setIsLoadingCycle(false);
        }
    };
    const render = () => {
        if (errorText) {
            return (
                <div style={{ marginTop: '20px' }}>
                    <Alert text={errorText} />
                </div>
            );
        }
        // if (isDataLoading) {
        //   return <Loader isGreen />;
        // }

        if (!Cats.data && !Cats.loading) {
            return (
                <div style={{ marginTop: '20px' }}>
                    <Alert text="No assessments" type="warning" />
                </div>
            );
        }
        return (
            <div style={{ position: 'relative' }}>
                <SelectWrapperS>
                    {step >= 1&&
                        <DefaultSelect
                            data={selectPackage}
                            icon={AssessmentIcon}
                            selected={selectedPackage}
                            label="Choose Package"
                            onChange={(e) => {
                                callAss(e)
                            }}
                            placeHolder="Choose Package"
                            isLoading={isLoadingPackage}
                        />
                    }
                    {step >= 2&&
                        <DefaultSelect
                            data={selectAssessment}
                            icon={AssessmentIcon}
                            selected={selectedAssessment}
                            label="Choose Assessment"
                            onChange={(e) => {
                                callCycles(e)
                                callGroups(e)
                            }}
                            placeHolder="Choose Assessment"
                            isLoading={isLoadingAss}
                        />
                    }
                    {step === 3&&
                        <>
                            <DefaultSelect
                                data={selectCycles}
                                label="Choose Cycle Or Period"
                                placeHolder="Choose Cycle Or Period"
                                icon={AssessmentIcon}
                                selected={ selectCycles[0] }
                                onChange={cycleSelectHandler}
                                activeSelects={activeCyclesLinks.length !== 0 ? activeCyclesLinks : [] }
                                isMultiple
                                isLoading={isLoadingCycle}
                            />
                            <OriginSourceGroupSelect
                                data={selectDataOriginSource}
                                icon={IdentityIcon}
                                onOriginSourceChange={originSourceSelectHandler}
                                onGroupChange={groupSelectHandler}
                                selected={selectDataOriginSource[0]}
                                label="Participant Group"
                                isMultiple
                                activeOriginSourceSelects={activeOriginSource}
                                activeGroupSelects={activeGroups}
                                // onCloseHandler={() => {
                                //     chartUseData.applyHandler();
                                // }}
                                isOpenGroupCategoryHandler={openHandler}
                                // setQuestionActiveSelects={chartUseData.setActiveQuestionIds}
                                // isAllQuestionsSelected={chartUseData.isAllQuestionsSelected}
                                // setIsAllQuestionsSelected={chartUseData.setIsAllQuestionsSelected}
                            />
                        </>
                    }
                </SelectWrapperS>

                <ButtonDefS
                    onClick={!isLoading ? () => genCSV() : () => null}
                    style={{ maxWidth: '97px' }}
                    disabled={tryMask || activeCyclesLinks.length === 0}
                >
                    {isLoading ? <Loader size={0.5} /> : 'Export'}
                </ButtonDefS>
            </div>
        );
    };

    return (
        <>
            <MainWrapperS>
                <PageTitleWrapperS>
                    <h1>AI Report</h1>
                </PageTitleWrapperS>
                <WrapperTitleIconS>
                    <XlsxIcon />
                    <h3>Export XLSX File</h3>
                    <InfoIS>
                        i
                        <div>
                            <div>
                                To load the file with raw data, please select the package (from the list of packages with answers),
                                then one of the package assessments  (from the assessments list with answers),
                                and then select the cycle/cycles from the list of cycles with answers on the selected assessment
                                (in the dropdown you can see the start dates of the cycles for choosing).
                                Additionally, select the group types, that should be presented in the report.
                            </div>
                        </div>
                    </InfoIS>
                </WrapperTitleIconS>
                {render()}
            </MainWrapperS>
            {tryMask ? (
                <AreYouShurePopUp
                    agreeText={'Yes, I am sure'}
                    disagreeText={'No, leave it checked'}
                    yesClick={popUpYesHandler}
                    noClick={popUpNoHandler}
                />
            ) : null}
        </>
    );
};

const InfoIS = styled.div`
  font-size: 12px;
  position: relative;
  left: 10px;
  top: 8px;
  transform: translateY(-50%);
  width: 16px;
  height: 16px;
  background: ${COLORS.accent};
  color: white;
  text-align: center;
  margin-left: 2px;
  border-radius: 10px;
  z-index: 3;

  & > div {
    background-color: white;
    font-weight: normal;
    font-size: 14px;
    display: none;
    position: absolute;
    top: 200%;
    width: 250px;
    left: -75px;
    border-radius: 10px;
    box-shadow: 0 11px 33px -9px rgba(42, 47, 42, 0.23);
    border: solid 1px rgba(162, 165, 162, 0.15);
    color: ${COLORS.default};
    text-align: left;
    padding: 15px;
  }

  &:hover {
    & > div {
      display: block;
    }
  }
`;

const WrapperTitleIconS = styled.div`
  display: flex;
  align-items: center;
  h3 {
    font-size: 1.6rem;
    margin: 0 0 0 10px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #171717;
  }
`;

const SelectWrapperS = styled.div`
  width: 100%;
  margin: 24px 0;
  max-width: 328px;

  & > * {
    margin-bottom: 15px;
  }

  .svgIconSelect {
    opacity: 0.3;
  }
`;

