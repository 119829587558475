import React from 'react';
import { useSelector } from 'react-redux';
import { AppStore } from '../../store/applicationState';
import { AnimatedClasses } from '../../utils/animatedStyles';
import { TEXT } from '../../utils/textConst';
import { ButtonBorderedS } from '../ui/buttons/styles';
import * as Styles from './modalStyles';
import { closeModal } from './utils/closeModal';
import styled from "styled-components";
import {COLORS} from "../../utils/colors";
import {callApi} from "../../utils/callApi";
import {API_ROUTE_PATH} from "../../utils/api_routes";
import {Alert} from "../ui";

interface InfoOfProcessingModalProps {
  closeHandler: () => void;
}

export const InfoOfProcessingModal: React.FC<InfoOfProcessingModalProps> = ({ closeHandler}) => {
    const { Workspaces } = useSelector((store: AppStore) => store);
    const [errorText, setErrorText] = React.useState('');
    const [isLoadingPackage, setIsLoadingPackage] = React.useState(true);
    const [processingData, setProcessingData] = React.useState<{
        package_id: string;
        package_name: string;
        assessment_id: boolean;
        assessment_name:string;
        period_start:string;
        period_end:string;
        status:string;
    }[]>([]);
  const refInnWrapper = React.useRef<HTMLDivElement>(null);

  const closeModalHandler = () => {
    if (refInnWrapper.current) closeModal(refInnWrapper.current, closeHandler);
  };


    React.useEffect(() => {
        const callFn = async () => {
            if (!Workspaces.current!.id)
                return setErrorText('No Workspaces id, ' + TEXT.tryLater);
            try {
                setIsLoadingPackage(true)
                const resp = (await callApi({
                    path: `${API_ROUTE_PATH.openAi.getStatus(
                        Workspaces.current!.id,
                    )}`,
                    method: 'get',
                })) as {
                package_id: string;
                package_name: string;
                assessment_id: boolean;
                assessment_name:string;
                period_start:string;
                period_end:string;
                status:string;
                }[];
                if (!Array.isArray(resp))
                    return setErrorText('Wrang format API data, ' + TEXT.tryLater);
                setProcessingData(resp)
            } catch (error) {
                setErrorText(`${error}`);
            } finally {
                setIsLoadingPackage(false);
            }
        };

        if (Workspaces.current) {
            callFn();
        }
    }, [Workspaces])

    const render = () => {
        if (errorText) {
            return (
                <div style={{marginTop: '20px'}}>
                    <Alert text={errorText}/>
                </div>
            );
        }

        return (<Styles.ModalOuterWrapperS>
            <Styles.ModalInnerWrapperS
                style={{ maxWidth: '800px' }}
                ref={refInnWrapper}
                className={AnimatedClasses.zoomIn}
            >
                <Styles.ModalHeaderS>
                    <Styles.ModalTileS>
                        Info of processing
                    </Styles.ModalTileS>
                </Styles.ModalHeaderS>
                <Styles.ModalBodyS>
                    {!isLoadingPackage && <ListOfProcessingItemsS>
                        {processingData.map((data, i) => (
                            <ProcessingItemsS>
                                <ItemsS>{data.package_name}</ItemsS>
                                <ItemsS>{data.assessment_name}</ItemsS>
                                <ItemsS>{data.period_start} to {data.period_end}</ItemsS>
                                <ItemsS style={{color: COLORS.accent}}>{data.status}</ItemsS>
                            </ProcessingItemsS>
                        ))}
                    </ListOfProcessingItemsS>}
                </Styles.ModalBodyS>
                <Styles.ModalFooterS>
                    <Styles.BtnsWrapperS>
                        <ButtonBorderedS isCancel onClick={closeModalHandler}>
                            Close
                        </ButtonBorderedS>
                    </Styles.BtnsWrapperS>
                </Styles.ModalFooterS>
            </Styles.ModalInnerWrapperS>
        </Styles.ModalOuterWrapperS>)
    }

    return (
        <>
            {render()}
        </>
    )
};

const ListOfProcessingItemsS = styled.div`
  display: flex;
  flex-direction: column;
`;

const ProcessingItemsS = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;
`;

const ItemsS = styled.div`
display: flex;
  width: 200px;
  justify-content: center;
  align-items: center;
`;
