import { EChartType } from '../store/analytics/types';
import {TMessageStatus} from '../store/packages/types';

export const API_ROUTE_PATH = {
  assessments: {
    path: '/assessments',
    genUM2M: function (assessmentId: string, packId: string) {
      return `${this.path}/${assessmentId}/to-package/${packId}`;
    },
    getAllByWorkspace: function (workspaceID: string) {
      return `${this.path}/list/by-workspace/${workspaceID}`;
    },
    getAnsweredExistingByWorkspace: function (workspaceID: string) {
      return `/assessments/list-answered-existing/by-workspace/${workspaceID}`;
    },
    getAnsweredByWorkspace: function (workspaceID: string, packageID: string) {
      return `/assessments/list-answered/workspace/${workspaceID}/package/${packageID}`;
    },
    getExistingInVisualizationsByWorkspace: function (workspaceID: string) {
      return `${this.path}/list-existing-in-visualizations/by-workspace/${workspaceID}`;
    },
    gArchived: '/assessments/list-archived/by-team',
    putArchived: function (assessmentId: string) {
      return `${this.path}/${assessmentId}/archivate`;
    },
    putUnarchived: function (assId: string, catId: string) {
      return `${this.path}/${assId}/unarchive-to-category/${catId}`;
    },
  },
  attachments: {
    path: '/attachments',
    getList: '/attachments/list/by-workspace',
    getUrl: function (attachmentId: string) {
      return `${this.path}/${attachmentId}/pre-signed-url`;
    },
    cOne: '/attachments/to-workspaces',
  },
  groups: {
    path: '/groups',
    pathWorkspace: '/groups/workspace',
    gAll: '/groups/by-workspace',
    gAllByOriginSource: function (originSource: string, wspID: string) {
      return `${this.path}/by-origin-source/${originSource}/by-workspace/${wspID}`;
    },
    gOriginSources: function (wspID: string) {
      return `${this.path}/origin-source/by-workspace/${wspID}`;
    },
    gOriginSourceAnsweredByWorkspaceID: function (wspID: string) {
      return `${this.path}/origin-source-answered/by-workspace/${wspID}`;
    },
  },
  categories: {
    path: '/categories',
    gList: '/categories/list/by-team',
    genGetAll: function (teamId: string) {
      return `${this.path}/by-team/${teamId}`;
    },
  },
  workspaces: {
    path: '/workspaces',
    pathByTeam: '/workspaces/by-team',
    genLink: function (workspaceId: string) {
      return `${this.path}/${workspaceId}/collaboration-link`;
    },
  },
  consultants: {
    path: '/consultants',
    gCurrent: '/consultants/current',
    gAll: '/consultants/by-team',
    uMe: '/consultants/update-me',
    genUM2M: function (consultantId: string, workspaceId: string) {
      return `${this.path}/${consultantId}/to-workspace/${workspaceId}`;
    },
  },
  //GenerateAiXlsxReport(ctx iris.Context)
  // CreateWorker(ctx iris.Context)
  // GetClosedPackageAndCyclesWithTextAnswersByWorkspaceID(ctx iris.Context)
  // GetGeneratedDatePackagesByWorkspaceID(ctx iris.Context)
  // GetGeneratedDateAssessmentsByWorkspaceID(ctx iris.Context)
  // GetGeneratedDatePeriodsByWorkspaceID(ctx iris.Context)
  // GetGeneratedDateGroupsByWorkspaceID(ctx iris.Context)
  // GetStatus(ctx iris.Context)
  openAi: {
    path: '/open-ai',
    createWorker: function (workspaceID: string) {
      return `${this.path}/create-worker/workspace/${workspaceID}`;
    },
    generateAiXlsxReport: function () {
      return `${this.path}/generation-report`;
    },
    getClosedPackageAndCyclesWithTextAnswersByWorkspaceID: function (workspaceID: string) {
      return `${this.path}/packages-and-cycles/by-workspace/${workspaceID}`;
    },
    getGeneratedDatePackagesByWorkspaceID: function (workspaceID: string) {
      return `${this.path}/packages/workspace/${workspaceID}`;
    },
    getStatus: function (workspaceID: string) {
      return `${this.path}/status/workspace/${workspaceID}`;
    },
    getGeneratedDateAssessmentsByWorkspaceID: function (workspaceID: string, packageID: string) {
      return `${this.path}/assessments/workspace/${workspaceID}/package/${packageID}`;
    },
    getGeneratedDatePeriodsByWorkspaceID: function (workspaceID: string, packageID: string, assessmentID: string,) {
      return `${this.path}/periods/workspace/${workspaceID}/package/${packageID}/assessment/${assessmentID}`;
    },
    getGeneratedDateGroupsByWorkspaceID: function (workspaceID: string, packageID: string, assessmentID: string,) {
      return `${this.path}/groups/workspace/${workspaceID}/package/${packageID}/assessment/${assessmentID}`;
    },
  },
  packages: {
    path: '/packages',
    cOne: '/packages/workspace',
    gAll: '/packages/by-workspace',
    gByLink: '/packages/by-link',
    genLink: function (packageId: string) {
      return `${this.path}/${packageId}/package_link`;
    },
    getAnsweredByWorkspace: function (workspaceID: string, withText: string) {
      return `/packages/list-answered/by-workspace/${workspaceID}/with-text/${withText}`;
    },
    getClosedPackageLinksWitTextQuestionListByWorkspace: function (workspaceID: string) {
      return `/packages/closed-package-links-wit-text-question-list/by-workspace/${workspaceID}`;
    },
    getAnsweredCyclesByWorkspace: function (workspaceID: string, packageID: string, assessmentID: string, withText: boolean) {
      if (withText) {
        return `/packages/answered-cycles-list/workspace/${workspaceID}/package/${packageID}/assessment/${assessmentID}/with-text/true`;
      } else {
        return `/packages/answered-cycles-list/workspace/${workspaceID}/package/${packageID}/assessment/${assessmentID}/with-text/false`;
      }

    },
    getPref: function (packageId: string) {
      return `${this.gByLink}/${packageId}/preferences`;
    },
    // same as above
    // getAllDelayed:function (packageId: string) {
    //   return `${this.gByLink}/${packageId}/preferences`;
    // },
    send: function (packageId: string) {
      return `${this.path}/${packageId}/send-by/email`;
    },
    delayedReg: function (packageId: string, status: TMessageStatus) {
      return `${this.path}/${packageId}/delayed-messages/email/${status}`;
    },
    delayedDraftReg: function (packageId: string) {
      return `${this.path}/${packageId}/delayed-messages/email/draft`;
    },
    delayedActions: function (packageId: string, messageId: string) {
      return `${this.path}/${packageId}/delayed-messages/${messageId}`;
    },
    delayedUpdateRegister: function (packageId: string, messageId: string) {
      return `${this.path}/${packageId}/delayed-messages/${messageId}/registered`;
    },
    delayedUpdate: function (
      packageId: string,
      messageId: string,
      status: TMessageStatus,
      trigger_days_after: number,
    ) {
      return `${this.path}/${packageId}/delayed-messages/${messageId}/${status}`;
    },
    testSend: function (packageId: string) {
      return `${this.path}/${packageId}/test-send-by/test_email`;
    },
  },
  messageTemplates: {
    path: '/message-templates',
    getAll: '/message-templates/list',
    updateTemplate: '/message-templates',
    deleteTemplate: function (templateId: string) {
      return `${this.path}/${templateId}`;
    },
  },
  participant: {
    notes: '/participant-notes',
    path: '/participant',
    addNote: function (id: string) {
      return `${this.notes}${this.path}/${id}`;
    },
    deleteNote: function (id: string) {
      return `${this.notes}/${id}`;
    },
    updateNote: function (id: string) {
      return `${this.notes}/${id}`;
    },
  },
  participants: {
    path: '/participants',
    cMultiply: '/participants/workspace',
    cMultiplyCsv: function (workspaceId: string) {
      return `${this.cMultiply}/${workspaceId}/by-csv`;
    },
    identify: '/participants/identify',
    gAllByWorkspace: '/participants/by-workspace',
    gAllByLink: '/participants/by-link',
    collaboration: '/participants/collaboration',
    c: '/participants/by-workspace',
    genM2M: function (participantId: string, groupId: string) {
      return `${this.path}/${participantId}/to-group/${groupId}`;
    },
    getParticipantDetails: function (id: string) {
      return `${this.path}/details/${id}`;
    },
  },
  answers: {
    path: '/answers',
  },
  links: {
    path: '/links',
  },
  reports: {
    path: '/reports',
    genCsvByParams: function (
      workspaceId: string,
      assessmentId: string | number,
      packageId: string,
    ) {
      return `${this.path}/raw-data/workspace/${workspaceId}/package/${packageId}/assessment/${assessmentId}`;
    },
    groupReportPath: function (
      workspaceId: string,
      packageId: string,
      ) {
        return `${this.path}/groups/workspace/${workspaceId}/package/${packageId}`;
      },
  },
  teams: {
    path: '/teams',
    gMy: '/teams/my-team',
  },
  admins: {
    consultPath: '/admin/consultants',
    teamsPath: '/admin/teams',
    csvPath: '/admin/report/network-map',
    groupCsvPath: function (
        workspaceId: string,
        packageId: string,
    ) {
      return `/admin/report/groups/workspace/${workspaceId}/package/${packageId}`;
    },
    benchmarkCsvPath: '/admin/report/benchmarks',
    resetConsultantPass: '/admin/reset-user-password',
    workspacesPath: function (teamId: string) {
      return `/admin/workspaces/by-team/${teamId}`;
    },
    packagesPath: function (workspaceId: string) {
      return `/admin/packages/by-workspace/${workspaceId}/orgvitals`;
    },
    allPackagesPath: function (workspaceId: string) {
      return `/admin/packages/by-workspace/${workspaceId}`;
    },
    cyclesPathClosed: function (packageId: string) {
      return `/admin/packages-cycles-closed/by-package/${packageId}`;
    },
    cyclesPathAll: function (packageId: string) {
      return `/admin/packages-cycles-all/by-package/${packageId}`;
    },
    getAnsweredByWorkspace: function (workspaceID: string, withText: boolean) {
      if (withText) {
        return `/admin/packages-list-answered/by-workspace/${workspaceID}/with-text/true`;
      } else {
        return `/admin/packages-list-answered/by-workspace/${workspaceID}/with-text/false`;
      }
    },
    getAnsweredCyclesByWorkspace: function (workspaceID: string, packageID: string, assessmentID: string, withText: boolean) {
      if (withText) {
        return `/admin/answered-cycles-list/workspace/${workspaceID}/package/${packageID}/assessment/${assessmentID}/with-text/true`;
      } else {
        return `/admin/answered-cycles-list/workspace/${workspaceID}/package/${packageID}/assessment/${assessmentID}/with-text/false`;
      }
    },
    groupsPath: function (
      workspaceId: string,
      packageId: string,
      cycleLink: string
    ) {
      return `/admin/groups/workspace/${workspaceId}/package/${packageId}/link/${cycleLink}`;
    },
    areasReport: '/admin/report/areas-of-focus',
  },
  visualizations: {
    workspacePath: '/visualizations/workspace',
    path: '/visualizations',
    genGAll: function (workspaceId: string, pageId: string) {
      return `${this.workspacePath}/${workspaceId}/page/${pageId}/define`;
    },
    getOneByParams: function (workspaceId: string, blockId: string) {
      return `${this.workspacePath}/${workspaceId}/define/${blockId}`;
    },
    createCustom: function (workspaceId: string, pageId: string) {
      return `${this.workspacePath}/${workspaceId}/page/${pageId}`;
    },
  },
  chart: {
    path: '/charts',
    createCustom: function (visId: string) {
      return `${this.path}/visualization/${visId}`;
    },
    getCurrent: function (
      workspace: string,
      chartId: string,
      type: EChartType
    ) {
      return `${this.path}/workspace/${workspace}/define/${chartId}/${type}`;
    },
    getParticipationRate: function (
      workspace: string,
      chartId: string,
      isPasses?: boolean
    ) {
      return `${this.path}/workspace/${workspace}/${
        isPasses ? 'passes' : 'info'
      }/${chartId}`;
    },
  },
  snapshot: {
    path: '/snapshots',
    byWorkspace: `/snapshots/by-workspace`,
    cWithFile: function (workspaceId: string) {
      return `${this.path}/by-workspace/${workspaceId}/file`;
    },
  },
  story: {
    path: '/stories',
    gByWorkspaceid: '/stories/by-workspace',
    gByLink: '/stories/by-link',
    linkByID: function (workspaceId: string) {
      return `${this.path}/workspace/${workspaceId}/link`;
    },
  },
  page: {
    gList: '/pages/list',
  },
  authentication: {
    refreshToken: '/refresh',
    authUser: '/authenticate',
  },
  users: {
    usersPath: '/users',
    signOut: '/users/sign-out',
    changePass: '/users/change-password',
  },
  collaborations: {
    path: '/collaborations',
    getByWorkspace: function (id: string) {
      return `${this.path}/by-workspace/${id}`;
    },
    gWithoutCollab: '/collaborations/participants-without-collaborations',
    getConnectednessScore: function (id: string) {
      return `${this.path}/connectedness-score/${id}`;
    },
  },
  assessmentCards: {
    path: '/assessment-cards',
    gAll: '/assessment-cards/workspace',
    // cOne: function (workspaceId: string, assessmentId: string) {
    //   return `${this.path}/workspace/${workspaceId}/assessment/${assessmentId}`;
    // },
    cMultiply: function (workspaceId: string) {
      return `${this.path}/workspace/${workspaceId}`;
    },
    uPositions: function (workspaceId: string) {
      return `/assessment-cards/workspace/${workspaceId}/positions`;
    },
  },
  mergeIntegrations: {
    path: '/merge-integrations',
    genLinkToken: function (workspaceId: string) {
      return `${this.path}/generate-link-token/workspace/${workspaceId}`;
    },
    retriveAccToken: function (workspaceId: string) {
      return `${this.path}/retrieve-account-token/workspace/${workspaceId}`;
    },
    gAll: function (workspaceId: string) {
      return `${this.path}/by-workspace/${workspaceId}/hris`;
    },
    receiveSynced: function (workspaceId: string) {
      return `${this.path}/receive-synced-data/${workspaceId}`;
    },
    forceSync: function (workspaceId: string) {
      return `${this.path}/force-sync/${workspaceId}`;
    },
  },
  finchIntegrations: {
    path: '/finch-integrations',
    genLinkToken: function (workspaceId: string) {
      return `${this.path}/generate-link-token/workspace/${workspaceId}`;
    },
    retriveAccToken: function (workspaceId: string) {
      return `${this.path}/retrieve-account-token/workspace/${workspaceId}`;
    },
    gAll: function (workspaceId: string) {
      return `${this.path}/by-workspace/${workspaceId}`;
    },
    // receiveSynced: function (workspaceId: string) {
    //   return `${this.path}/receive-synced-data/${workspaceId}`;
    // },
    // forceSync: function (workspaceId: string) {
    //   return `${this.path}/force-sync/${workspaceId}`;
    // },
  },
  matchingTemplates: {
    path: '/matching-template',
    gOne: function (workspaceId: string) {
      return `${this.path}/by-workspace/${workspaceId}`;
    },
  },
};
