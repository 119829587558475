import React from 'react';
import { AnimatedClasses } from '../../utils/animatedStyles';
import { ButtonBorderedS } from '../ui/buttons/styles';
import * as Styles from './modalStyles';
import { closeModal } from './utils/closeModal';

interface InfoAIModalProps {
  closeHandler: () => void;
}

export const InfoAIModal: React.FC<InfoAIModalProps> = ({ closeHandler}) => {
  const refInnWrapper = React.useRef<HTMLDivElement>(null);

  const closeModalHandler = () => {
    if (refInnWrapper.current) closeModal(refInnWrapper.current, closeHandler);
  };

  return (
      <Styles.ModalOuterWrapperS>
        <Styles.ModalInnerWrapperS
            style={{ maxWidth: '500px' }}
            ref={refInnWrapper}
            className={AnimatedClasses.zoomIn}
        >
          <Styles.ModalHeaderS>
            <Styles.ModalTileS>
             Info
            </Styles.ModalTileS>
          </Styles.ModalHeaderS>
          <Styles.ModalBodyS style={{fontWeight: '14px'}}>
              The AI Analysis will be generated.<br/>
              Please, review the progress in the “Info of processing” option.
          </Styles.ModalBodyS>
          <Styles.ModalFooterS>
            <Styles.BtnsWrapperS>
              <ButtonBorderedS isCancel onClick={closeModalHandler}>
                Close
              </ButtonBorderedS>
            </Styles.BtnsWrapperS>
          </Styles.ModalFooterS>
        </Styles.ModalInnerWrapperS>
      </Styles.ModalOuterWrapperS>
  );
};
