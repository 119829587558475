const visualizations = '/visualizations';
// const assessments = '/assessments';
const admin = '/admin';

export const NAV_IDS = {
  ass: 'assessments',
  rep: 'reports',
  vis: 'analytics',
  ai: 'ai'
};

export const ROUTE_PATH = {
  home: '/home',
  participants: '/participants',
  login: '/login',
  forgotPass: '/forgot-password',
  signUp: '/sign-up',
  workspaceSettings: '/workspace-settings',
  myAssessments: '/' + NAV_IDS.ass + '/my-assessments',
  orgVitalsAssessments: '/' + NAV_IDS.ass + '/orgvitals-assessments',
  communityAssessments: '/' + NAV_IDS.ass + '/community-assessments',
  archivedAssessments: '/' + NAV_IDS.ass + '/archived-assessments',
  packageSchedule: '/package-schedule',
  visualizations,
  rawData: '/' + NAV_IDS.rep + '/raw-data',
  groupReport: '/' + NAV_IDS.rep + '/group-report',
  aiGeneration: '/' + NAV_IDS.ai + '/ai-generation',
  aiReport: '/' + NAV_IDS.ai + '/ai-report',
  dataStory: '/data-story',
  dataStoryPreview: '/data-story-preview/:id',
  dataStoryView: '/data-story-view/:id',
  appVersion: '/app-version',
  assResponding: '/assessment-responding/:id',
  collabResponding: '/collaborator-responding/:id',
  consultingTeam: '/consulting-team',
  myAccount: '/my-account',
  csvReader: '/csvReader',
  admin,
  adminConsultants: admin + '/teams',
  adminAreasOfFocus: admin + '/areas-of-focus',
  adminNetworkMap: admin + '/network-map',
  adminGroupsCsvReport: admin + '/groups-csv-report',
  adminBenchmarksCsvReport: admin + '/benchmarks-csv-report',
  visualCulture: '/' + NAV_IDS.vis + '/culture',
  visualCollaboration: '/' + NAV_IDS.vis + '/collaboration',
  visualPeople: '/' + NAV_IDS.vis + '/people',
};
