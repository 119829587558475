import { NAV_IDS, ROUTE_PATH } from '../../utils/routes';
import { getUniqueId } from '../../utils/uniqueId';
import {
  AssessmentIcon,
  DataStoryIcon,
  PackageIconMenu,
  ParticipantsIcon,
  RowDataIcon,
  VisualizationIcon,
} from '../svgs';
import { HomeIcon } from '../svgs/HomeIcon';
import {AIChip} from "../svgs/AIChip";

const genNavId = getUniqueId();

export type TNav2 = {
  id: number | void | string;
  href: string;
  exact: boolean;
  icon: React.FC | null;
  title: string;
  outerHref?: boolean;
  nav?: TNav2[];
};
type TNav = {
  subtitle: string;
  id: number | void | string;
  nav: TNav2[];
};
export const NAVS: TNav[] = [
  {
    subtitle: '',
    id: genNavId.next().value as number,
    nav: [
      {
        id: genNavId.next().value,
        href: '/',
        exact: true,
        icon: HomeIcon,
        title: 'Home',
      },
    ],
  },
  {
    subtitle: 'CAPTURE',
    id: genNavId.next().value,
    nav: [
      {
        id: genNavId.next().value,
        href: ROUTE_PATH.participants,
        exact: true,
        icon: ParticipantsIcon,
        title: 'Participants',
      },
      {
        id: NAV_IDS.ass,
        href: '',
        exact: false,
        icon: AssessmentIcon,
        title: 'Assessment Library',
        nav: [
          {
            id: genNavId.next().value,
            href: ROUTE_PATH.myAssessments,
            exact: false,
            icon: null,
            title: 'My Library',
          },
          {
            id: genNavId.next().value,
            href: ROUTE_PATH.orgVitalsAssessments,
            exact: false,
            icon: null,
            title: 'OrgVitals Library',
          },
          {
            id: genNavId.next().value,
            href: 'https://blockchain.orgvitals.com/',
            outerHref: true,
            exact: false,
            icon: null,
            title: 'Blockchain Surveys',
          },
          {
            id: genNavId.next().value,
            href: ROUTE_PATH.archivedAssessments,
            exact: false,
            icon: null,
            title: 'Archived Assessments',
          },
          // {
          //   id: genNavId.next().value,
          //   href: ROUTE_PATH.communityAssessments,
          //   exact: false,
          //   icon: null,
          //   title: 'Community Assessments',
          // },
        ],
      },
      {
        id: genNavId.next().value,
        href: ROUTE_PATH.packageSchedule,
        exact: false,
        icon: PackageIconMenu,
        title: 'Package Schedule',
      },
    ],
  },
  {
    subtitle: 'LEARN',
    id: genNavId.next().value,
    nav: [
      {
        id: NAV_IDS.vis,
        href: '',
        exact: false,
        icon: VisualizationIcon,
        title: 'Analytics',
        nav: [
          {
            id: genNavId.next().value,
            href: ROUTE_PATH.visualCulture,
            exact: false,
            icon: null,
            title: 'Culture',
          },
          {
            id: genNavId.next().value,
            href: ROUTE_PATH.visualCollaboration,
            exact: false,
            icon: null,
            title: 'Collaboration',
          },
          // {
          //   id: genNavId.next().value,
          //   href: ROUTE_PATH.visualPeople,
          //   exact: false,
          //   icon: null,
          //   title: 'People',
          // },
        ],
      },
      {
        id: NAV_IDS.rep,
        href: '',
        exact: false,
        icon: RowDataIcon,
        title: 'Reports',
        nav: [
          {
            id: genNavId.next().value,
            href: ROUTE_PATH.rawData,
            exact: false,
            icon: null,
            title: 'Raw Data',
          },
          {
            id: genNavId.next().value,
            href: ROUTE_PATH.groupReport,
            exact: false,
            icon: null,
            title: 'Group Report',
          },
        ],
      },
      {
        id: NAV_IDS.ai,
        href: '',
        exact: false,
        icon: AIChip,
        title: 'AI',
        nav: [
          {
            id: genNavId.next().value,
            href: ROUTE_PATH.aiGeneration,
            exact: false,
            icon: null,
            title: 'AI Generate',
          },
          {
            id: genNavId.next().value,
            href: ROUTE_PATH.aiReport,
            exact: false,
            icon: null,
            title: 'AI Report',
          },
        ],
      },
    ],
  },
  {
    subtitle: 'LEAD',
    id: genNavId.next().value,
    nav: [
      {
        id: genNavId.next().value,
        href: ROUTE_PATH.dataStory,
        exact: false,
        icon: DataStoryIcon,
        title: 'Data Story',
      },
    ],
  },
];
