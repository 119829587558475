import moment from 'moment';
import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import styled from 'styled-components';
import {AppStore} from '../../store/applicationState';
import {setInfoModal} from '../../store/modal/actions';
import {EditPackage} from '../../store/packages/actions';
import {
  EMessageStatusType,
  ETypeOfMessageType,
  ETypeOfLinkBeingSent,
  TDelayedMessage,
  TDelayedMessagesGroups,
  TMessageStatus,
  TPackage,
} from '../../store/packages/types';
import { API_ROUTE_PATH } from '../../utils/api_routes';
import { callApi } from '../../utils/callApi';
import { changeTimeZoneToEst } from '../../utils/changeTimeZoneToEst';
import {COLORS, COLORS_RGBA} from '../../utils/colors';
import { convertEposToRenderDate } from '../../utils/convertEposToDate';
import { deepClone } from '../../utils/deepClone';
import { TEXT } from '../../utils/textConst';
import { VALUES } from '../../utils/valueConst';
import { month_names_short } from '../participants/ParticipantRow';
import { LinkIcon, PackageIcon } from '../svgs';
import { EditIcon } from '../svgs/EditIcon';
import { ExclamationIcon } from '../svgs/ExclamationIcon';
import { ScheduleIcon } from '../svgs/ScheduleIcon';
import { SendIcon } from '../svgs/SendIcon';
import {
  ButtonMore,
  DeleteSelectItem,
  EditSelectItem,
  Loader,
  SendSelectItem,
  ViewSelectItem,
} from '../ui';
import { ButtonBorderedS } from '../ui/buttons/styles';
import {RecyclingIcon} from "../svgs/recycling";
import {Participation} from "./Participation";

interface PackageRowProps {
  pack: TPackage;
  packages: TPackage[];
  copyLinkHandler: () => void;
  sendHandler: () => void;
  sendTestHandler: (message: TDelayedMessage) => void;
  editScheduleHandler: (message: TDelayedMessage) => void;
  viewSentHandler: (message: TDelayedMessage) => void;
}

export const PackageRow: React.FC<PackageRowProps> = ({
  pack,
  packages,
  copyLinkHandler,
  sendHandler,
  sendTestHandler,
  editScheduleHandler,
  viewSentHandler,
}) => {
  const { Packages } = useSelector((store: AppStore) => store);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isLinkExisting, setIsLinkExisting] = React.useState(false);
  const [linkCreatingDate, setLinkCreatingDate] = React.useState(
    new Date(pack.link_created_at)
  );

  const currentUnixTimestamp = Math.floor(Date.now() / 1000);

  const dispatch = useDispatch();

  const deleteScheduledHandler = async (
    packageId: string,
    messageId: string
  ) => {
    try {
      await callApi({
        path: API_ROUTE_PATH.packages.delayedActions(packageId, messageId),
        method: 'delete',
      });

      const newPaks = deepClone(Packages.data) as TPackage[];
      const mesageIndex = newPaks
        .find((pack) => pack.id === packageId)
        .delayed_message.findIndex((msg) => msg.id === messageId);

      newPaks
        .find((pack) => pack.id === packageId)
        .delayed_message.splice(mesageIndex, 1);

      let has_delayed = false;
      let biggestDate: string = '';
      newPaks
        .find((pack) => pack.id === packageId)
        .delayed_message.forEach((del_message) => {
          if (!has_delayed) {
            if (del_message.status === EMessageStatusType.registered)
              has_delayed = true;
          }
          if (
            del_message.status === EMessageStatusType.registered &&
            del_message.send_at &&
            del_message.send_at !== '0001-01-01T00:00:00Z'
          ) {
            if (
              biggestDate &&
              new Date(biggestDate) < new Date(del_message.send_at)
            ) {
              biggestDate = del_message.send_at;
            } else if (!biggestDate) {
              biggestDate = del_message.send_at;
            }
          }
        });

      newPaks.find((pack) => pack.id === packageId).is_has_delayed_message =
        has_delayed;

      if (biggestDate) {
        newPaks.find((pack) => pack.id === packageId).min_number_of_days =
          moment(biggestDate).diff(moment(), 'days') + 1;
      } else {
        newPaks.find((pack) => pack.id === packageId).min_number_of_days = 0;
      }

      dispatch(EditPackage.success(newPaks));
    } catch (e) {
      console.log(e);
      dispatch(EditPackage.error(e));
    } finally {
    }
  };

  React.useEffect(() => {
    return () => setIsLoading(false);
  }, []);

  React.useEffect(() => {
    if (pack.link_expiration === 0 && !pack.is_forever) {
      setIsLinkExisting(false);
    }
    setLinkCreatingDate(new Date(pack.link_created_at));
    const expiration = new Date(0);
    expiration.setUTCSeconds(pack.link_expiration);
    const currentDate = new Date();
    if (expiration.getTime() > currentDate.getTime() || pack.is_forever) {
      setIsLinkExisting(true);
    }
  }, [pack.link_created_at, pack.link_expiration, pack.is_forever]);

  const callBack = (success: boolean) => {
    setIsLoading(false);
    if (!success) return null;
    if (!packages) return null;
    const newPackages = deepClone(packages) as TPackage[];
    const newPack = newPackages.find((p) => p.id === pack.id);
    if (!newPack) {
      dispatch(
        setInfoModal({
          mess: 'Could not find package ' + TEXT.tryLater,
        })
      );
      console.error('Could not find package');
      return null;
    }
    newPack.is_scheduled = false;

    return dispatch(EditPackage.success(newPackages));
  };

  const deleteFn = () => {
    dispatch(
      EditPackage.request({
        id: pack.id,
        data: {
          description: pack.description,
          name: pack.name,
          is_scheduled: false,
          logo_team: false,
          logo_workspace: false,
          languages_tags: pack.languages_tags,
          welcome_screen_description: { en_US: '' },
          welcome_screen_title: { en_US: '' },
        },
        callBack,
      })
    );
  };

  const renderMessageIcon = (status: TMessageStatus, isDis?: boolean) => {
    switch (status) {
      case EMessageStatusType.sent:
        return <SendIcon color={`${COLORS.accent}`} opacity={'1'} />;
      case EMessageStatusType.registered:
        if(isDis) {
          return <ScheduleIcon color={`${COLORS_RGBA.default(0.3)}`}  opacity={'1'}/>;
        }
        return <ScheduleIcon color={`${COLORS.accent}`}  opacity={'1'}/>;
      case EMessageStatusType.triggered:
        if(isDis) {
          return <RecyclingIcon color={`${COLORS_RGBA.default(0.3)}`}  opacity={'1'}/>;
        }
        return <RecyclingIcon color={`${COLORS.accent}`}  opacity={'1'}/>;
      case EMessageStatusType.expired:
        return <ExclamationIcon color={`${COLORS.danger}`}/>;
      case EMessageStatusType.failed:
        return <ExclamationIcon color={`${COLORS.danger}`} />;
      case EMessageStatusType.draft:
        return <EditIcon color={`#ff9d02`} opacity={'1'} />;
    }
  };

  const delayedOrder = [
    EMessageStatusType.sent,
    EMessageStatusType.registered,
    EMessageStatusType.triggered,
    EMessageStatusType.draft,
    EMessageStatusType.expired,
    EMessageStatusType.failed,
  ];

  const getGroupsNames = (delayedGroups: TDelayedMessagesGroups[]) => {
    const groupNames: string[] = [];
    delayedGroups.forEach((group) => {
      groupNames.push(group.group.name);
    });
    return groupNames.join(', ');
  };

  return (
    <WrapperS key={pack.id}>
      <RowS>
        <div>
          {isLoading ? <Loader isGreen /> : null}
          <PackageTitleWrapperS>
            <PackageIcon />
            <span>{`${pack.name}${isLinkExisting ? ' - ' : ''}`}</span>
            {isLinkExisting && (
              <PackageDatesWrapperS isYellow={pack.is_paused && pack.is_forever}>
                <p>
                  {pack.is_paused && pack.is_forever ? 'Disabled ': 'Open '}
                  {`${
                    month_names_short[linkCreatingDate.getMonth()]
                  } ${linkCreatingDate.getDate()} - ${pack.is_forever? 'forever' : convertEposToRenderDate(
                      pack.link_expiration
                  )}`}
                </p>
              </PackageDatesWrapperS>
            )}
            {pack.is_forever && <Participation notified={pack.count_sent_unique_emails.notified} surveyed={pack.count_sent_unique_emails.surveyed}/>}
          </PackageTitleWrapperS>
        </div>

        {isLoading ? null : (
          <ButtonsWrapperS>
            <div>
              {!pack.assessments[0] ? (
                <span
                  style={{
                    whiteSpace: 'nowrap',
                    display: 'block',
                    paddingRight: '10px',
                  }}
                >
                  No assessments &nbsp;&nbsp;&nbsp;{' '}
                </span>
              ) : (
                <BtnsWrapperS>
                  <BtnScheduleS onClick={copyLinkHandler}>
                    <LinkIcon />
                    Copy link
                  </BtnScheduleS>
                  <BtnSend
                    onClick={sendHandler}
                    hasButton={pack.is_has_delayed_message}
                  >
                    <SendSelectItem title={''} />
                    Schedule messages
                  </BtnSend>
                </BtnsWrapperS>
              )}
            </div>

                {!pack.is_has_delayed_message && (
                    <ButtonMore
                        data={[
                          // {
                          //   title: <SendSelectItem />,
                          //   value: VALUES.send,
                          //   id: 2,
                          // },
                          {
                            title: <DeleteSelectItem title="Delete from schedule" />,
                            value: VALUES.delete,
                            id: 1,
                          },
                        ]}
                        onClick={(val) => {
                          switch (val) {
                            case VALUES.delete: {
                              setIsLoading(true);
                              return deleteFn();
                            }
                            case VALUES.send: {
                              return sendHandler();
                            }
                            default:
                              return null;
                          }
                        }}
                    />
                )}
              </ButtonsWrapperS>
          )}
        </RowS>
        {pack.delayed_message && pack.delayed_message[0] && (
            <ListWrapperS>
              {pack.delayed_message
                  .sort(
                      (a, b) =>
                          delayedOrder.indexOf(a.status) - delayedOrder.indexOf(b.status)
                  )
                  .map((message) => {
                    let send_date = new Date();
                    if (message.send_at && message.send_at !== '0001-01-01T00:00:00Z')
                      send_date = changeTimeZoneToEst(message.send_at);
                    let hours = send_date.getHours();
                    const AmOrPm = hours >= 12 ? 'PM' : 'AM';
                    hours = hours % 12 || 12;
                    const minutes =
                        (send_date.getMinutes() < 10 ? '0' : '') +
                        send_date.getMinutes();
                    return (
                        <MessageWrapperS isDisabled={pack && pack.is_paused} key={message.id}>
                          {renderMessageIcon(message.status, (pack && pack.is_paused))}
                          <ItemsWrapperS className={message.status}>
                            {(message.status === EMessageStatusType.registered ||
                                    message.status === EMessageStatusType.sent) &&
                                message.send_at &&
                                message.send_at !== '0001-01-01T00:00:00Z' && (
                                    <p>{`${
                                        month_names_short[send_date.getMonth()]
                                    } ${send_date.getDate()}, ${
                                        hours + ':' + minutes + ' ' + AmOrPm
                                    }`}</p>
                                )}

                            {message.status === EMessageStatusType.draft && (message.type_of_message === ETypeOfMessageType.schedule ||
                                    message.type_of_message === ETypeOfMessageType.not_selected ) &&
                                message.send_at === '0001-01-01T00:00:00Z' && (
                                    <p>Not Selected</p>
                                )}

                            {message.status === EMessageStatusType.draft && (message.type_of_message === ETypeOfMessageType.schedule ||
                                    message.type_of_message === ETypeOfMessageType.not_selected ) &&
                                message.send_at !== '0001-01-01T00:00:00Z' && (
                                    <p>{`${
                                        month_names_short[send_date.getMonth()]
                                    } ${send_date.getDate()}, ${
                                        hours + ':' + minutes + ' ' + AmOrPm
                                    }`}</p>
                                )}

                            {message.status === EMessageStatusType.draft && message.type_of_message === ETypeOfMessageType.trigger && (
                                message.trigger_days_after > 1 ?
                                    <p>{`+${message.trigger_days_after} days, ${
                                        hours + ':' + minutes + ' ' + AmOrPm
                                    }` } </p>
                                    : <p>{`+${message.trigger_days_after} day, ${
                                        hours + ':' + minutes + ' ' + AmOrPm
                                    }` } </p>
                            )}

                            {message.status === EMessageStatusType.triggered && message.trigger_days_after && (
                                message.trigger_days_after > 1 ?
                                    <p>{`+${message.trigger_days_after} days, ${
                                        hours + ':' + minutes + ' ' + AmOrPm
                                    }` } </p>
                                    : <p>{`+${message.trigger_days_after} day, ${
                                        hours + ':' + minutes + ' ' + AmOrPm
                                    }` } </p>
                            )}
                          </ItemsWrapperS>
                          <ItemsWrapperS
                              className={message.status}
                              title={message.metadata.email_subject}
                          >
                            <p>{message.metadata.email_subject}</p>
                          </ItemsWrapperS>
                          <ItemsWrapperS
                              style={{width: '35%'}}
                              className={message.status}
                              title={
                                message.delayed_messages_to_groups &&
                                message.delayed_messages_to_groups[0]
                                    ? getGroupsNames(message.delayed_messages_to_groups)
                                    : 'All Participants'
                              }
                          >
                            <p>{`${
                                message.delayed_messages_to_groups &&
                                message.delayed_messages_to_groups[0]
                                    ? `${message.delayed_messages_to_groups.length} Group${
                                        message.delayed_messages_to_groups.length > 1
                                            ? 's'
                                            : ''
                                    }`
                                    : 'All Participants'
                            }, ${message.type_of_link_being_sent === ETypeOfLinkBeingSent.publicLink ? 'General link' : 'Individual links'}`}</p>
                          </ItemsWrapperS>
                          {message.status !== EMessageStatusType.failed && (
                              <ButtonMore
                                  data={
                                    message.status !== EMessageStatusType.sent ? (currentUnixTimestamp <= pack.link_expiration || (pack.is_forever&& message.status === EMessageStatusType.draft)) ? [
                                          {
                                            title: <SendSelectItem title="Test" />,
                                            value: VALUES.send,
                                            id: 1,
                                          },
                                          {
                                            title: <EditSelectItem />,
                                            value: VALUES.edit,
                                            id: 2,
                                          },
                                          {
                                            title: <DeleteSelectItem />,
                                            value: VALUES.delete,
                                            id: 3,
                                          },
                                        ] : [
                                          {
                                            title: <SendSelectItem title="Test" />,
                                            value: VALUES.send,
                                            id: 1,
                                          },
                                          {
                                            title: <DeleteSelectItem />,
                                            value: VALUES.delete,
                                            id: 3,
                                          },
                                        ]
                                        : [
                                          {
                                            title: <ViewSelectItem title="View Message" />,
                                            value: VALUES.view,
                                            id: 4,
                                          },
                                        ]
                                  }
                                  onClick={(val) => {
                                    switch (val) {
                                      case VALUES.send: {
                                        return sendTestHandler(message);
                                      }
                                      case VALUES.edit: {
                                        if (message.status === EMessageStatusType.draft &&
                                            (currentUnixTimestamp > pack.link_expiration &&
                                                !pack.is_forever)) {
                                          return null
                                        }
                                        return editScheduleHandler(message);
                                      }
                                      case VALUES.view: {
                                        return viewSentHandler(message);
                                      }
                                      case VALUES.delete: {
                                        return deleteScheduledHandler(pack.id, message.id);
                                      }
                                      default:
                                        return null;
                                    }
                                  }}
                              />
                          )}
                        </MessageWrapperS>
                    );
                  })}
            </ListWrapperS>
        )}
        {/* <ButtonAddWrapperS>
        <ButtonAdd title={'Plan message'} onClick={() => {}} />
      </ButtonAddWrapperS> */}
    </WrapperS>
  );
};

const WrapperS = styled.div`
  border-bottom: 1px solid ${COLORS.secondary};
`;
const ListWrapperS = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 25px;
  width: 75%;
  margin-bottom: 15px;
`;
// const ButtonAddWrapperS = styled.div`
//   margin-left: 25px;
// `;
const MessageWrapperS = styled.div<{ isDisabled?: boolean }>`
  display: flex;
  align-items: center;
  width: 100%;
  margin: 5px 0;
  p {
    margin: 0;
    font-size: 16px;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .btn-more {
    transform: rotate(90deg);
  }
  svg {
    min-width: 24px;
    max-width: 24px;
  }
  .registered {
    border: 1px solid ${({ isDisabled }) => (isDisabled ? COLORS_RGBA.default(0.3) : COLORS.accent)};
    p {
      color: ${({ isDisabled }) => (isDisabled ? COLORS_RGBA.default(0.3) : COLORS.accent)};
    }
  }
  .triggered {
    border: 1px solid ${({ isDisabled }) => (isDisabled ? COLORS_RGBA.default(0.3) : COLORS.accent)};
    p {
      color: ${({ isDisabled }) => (isDisabled ? COLORS_RGBA.default(0.3) : COLORS.accent)};
    }
  }
  .send {
    border: 1px solid ${COLORS.accent};
    /* width: calc(25% + 8px); */
    p {
      color: ${COLORS.accent};
    }
  }
  .sent {
    border: 1px solid ${COLORS.accent};
    /* width: calc(25% + 8px); */
    p {
      color: ${COLORS.accent};
    }
  }
  .failed {
    border: 1px solid ${COLORS.danger};
    /* width: calc(25% + 8px); */
    p {
      color: ${COLORS.danger};
    }
  }
  .expired {
    border: 1px solid ${COLORS.danger};
    /* width: calc(25% + 8px); */
    p {
      color: ${COLORS.danger};
    }
  }
  .draft {
    border: 1px solid #ff9d02;
    p {
      color: #ff9d02;
    }
  }
`;
const ItemsWrapperS = styled.div`
  border: 1px solid ${COLORS.accent};
  text-align: center;
  padding: 5px 10px;
  border-radius: 8px;
  margin: 0 5px;
  width: 25%;
`;
const RowS = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  padding: 16px 0;
  justify-content: space-between;
  .btn-more {
    transform: rotate(90deg);
  }
`;
const ButtonsWrapperS = styled.div`
  display: flex;
  align-items: center;
`;
const BtnScheduleS = styled(ButtonBorderedS)`
  width: 122px;
  display: flex;
  align-items: center;
  color: ${COLORS.default};
  font-size: 1.6rem;
  margin-right: 15px;
  height: 36px;
  min-height: 36px;
  white-space: nowrap;
  svg {
    opacity: 0.3;
    margin-right: 10px;
  }
`;

const PackageTitleWrapperS = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  svg {
    margin-right: 12px;
  }
  span {
    max-width: 800px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    flex: 1;
    max-width: 90%;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: ${COLORS.accent};
    display: block;
  }
`;
const PackageDatesWrapperS = styled.div<{ isYellow?: boolean }>`
  border: 1px solid ${({ isYellow }) => (isYellow ? COLORS.orangeActive : COLORS.accent)};
  padding: 5px 10px;
  border-radius: 8px;
  margin-left: 5px;
  p {
    margin: 0;
    font-size: 16px;
    color:${({ isYellow }) => (isYellow ? COLORS.orangeActive : COLORS.accent)};
  }
`;
const BtnsWrapperS = styled.div`
  
  display: flex;
`;

const BtnSend = styled(BtnScheduleS)<{ hasButton: boolean }>`
  width: auto;
  margin-right: ${({ hasButton }) => (!hasButton ? '15px' : '39px')};
  svg {
    opacity: 1;
  }
`;
