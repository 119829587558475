import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { Env } from './components/Env';
import { InfoModal } from './components/modals/InfoModal';
import { Loader } from './components/ui';
import { LoggedInComponents } from './LoggedInComponents';
import * as Screens from './pages';
import { AppStore } from './store/applicationState';
import { getMyR } from './store/team/actions';
import { getMeR } from './store/user/actions';
import { checkToken } from './utils/checkToken';
import { COLORS } from './utils/colors';
import { ENV } from './utils/environment';
import { ROUTE_PATH } from './utils/routes';

// prettier-ignore
function App() {
  const { User } = useSelector((store: AppStore) => store);
  const [isLoading, setIsLoading] = React.useState(true);
  const dispatch = useDispatch();

  React.useEffect(() => {
    document.getElementById('mainLoader')?.remove();
    setIsLoading(true);
    (async () => {
      const isValidToken = await checkToken();
      if (isValidToken.success) {
        dispatch(getMeR(() => dispatch(getMyR(() => setIsLoading(false)))));
      } else {
        setIsLoading(false);
      }
    })();
  }, [dispatch]);

  if (isLoading) {
    return <Loader color={COLORS.accent} />;
  }
  if ((User.isLoggedIn && window.innerWidth < 996) || (window.location.href.includes('data-story-view') && window.innerWidth < 996) ) {
    return <Screens.WelcomeScreenMobile />;
  }

  const htmlENV = ENV.isDev || ENV.isStage ? <Env /> : null;

  if (User.isLoggedIn) {
    return (
      <>
        <LoggedInComponents />
        <Switch>
          <Route exact path='/' component={Screens.Home}/>
          <Route exact path={ROUTE_PATH.participants}  component={Screens.Participants} />
          <Route
            exact
            path={ROUTE_PATH.workspaceSettings}
            component={Screens.WorkspaceSettings}
          />
          <Route
            exact
            path={ROUTE_PATH.myAssessments}
            component={Screens.MyAssessments}
          />
          <Route exact path={ROUTE_PATH.orgVitalsAssessments}>
            <Screens.MyAssessments type="default" />
          </Route>
          <Route
            exact
            path={ROUTE_PATH.archivedAssessments}
            component={Screens.ArchivedAssessments}
          />
          <Route
            exact
            path={ROUTE_PATH.appVersion}
            component={Screens.AppVersion}
          />
          <Route
            exact
            path={ROUTE_PATH.packageSchedule}
            component={Screens.PackageSchedule}
          />
          <Route
            exact
            path={ROUTE_PATH.assResponding}
            component={Screens.RespondingIsAuth}
          />
          {/* <Route exact path={ROUTE_PATH.collabResponding} component={Screens.RespondingIsAuth}/> */}
          <Route
              exact
              path={ROUTE_PATH.rawData}
              component={Screens.RowData}
          />
          <Route
              exact
              path={ROUTE_PATH.groupReport}
              component={Screens.GroupReport}
          />
          <Route
              exact
              path={ROUTE_PATH.aiReport}
              component={Screens.AiReport}
          />
          <Route
              exact
              path={ROUTE_PATH.aiGeneration}
              component={Screens.AiGenerate}
          />
          <Route
            exact
            path={ROUTE_PATH.dataStory}
            component={Screens.DataStory}
          />
          <Route
            exact
            path={ROUTE_PATH.dataStoryPreview}
            component={Screens.DataStoryView}
          />
          <Route
            exact
            path={ROUTE_PATH.dataStoryView}
            component={Screens.DataStoryViewIsAuth}
          />
          <Route
            exact
            path={ROUTE_PATH.consultingTeam}
            component={Screens.ConsultingTeam}
          />
          <Route
            exact
            path={ROUTE_PATH.myAccount}
            component={Screens.MyAccount}
          />
          <Route
            exact
            path={ROUTE_PATH.csvReader}
            component={Screens.CsvReader}
          />
          <Route
            exact
            path={ROUTE_PATH.visualCulture}
            component={Screens.Culture}
          />
          <Route
            exact
            path={ROUTE_PATH.visualCollaboration}
            component={Screens.Collaboration}
          />
          <Route
            exact
            path={ROUTE_PATH.visualPeople}
            component={Screens.People}
          />
          <Route exact path={ROUTE_PATH.admin} component={Screens.AdminHome} />
          <Route
            exact
            path={ROUTE_PATH.adminConsultants}
            component={Screens.Consultants}
          />
          <Route
            exact
            path={ROUTE_PATH.adminConsultants}
            component={Screens.Consultants}
          />
          <Route
            exact
            path={ROUTE_PATH.adminAreasOfFocus}
            component={Screens.AreasOfFocus}
          />
          <Route
              exact
              path={ROUTE_PATH.adminNetworkMap}
              component={Screens.NetworkMapReport}
          />
          <Route
              exact
              path={ROUTE_PATH.adminGroupsCsvReport}
              component={Screens.GroupsReport}
          />
        </Switch>
        {htmlENV}
        <InfoModal />
      </>
    );
  }

  return (
    <>
      <Switch>
        <Route exact path={ROUTE_PATH.login} component={Screens.Login} />
        <Route
          exact
          path={ROUTE_PATH.forgotPass}
          component={Screens.ForgotPass}
        />
        <Route
          exact
          path={ROUTE_PATH.appVersion}
          component={Screens.AppVersion}
        />
        <Route
          exact
          path={ROUTE_PATH.assResponding}
          component={Screens.Responding}
        />
        <Route
            exact
            path={ROUTE_PATH.dataStoryView}
            component={Screens.DataStoryView}
          />
        {/* <Route exact path={ROUTE_PATH.collabResponding} component={Screens.CollabResponding}/> */}
        <Route
          exact
          path={ROUTE_PATH.csvReader}
          component={Screens.CsvReader}
        />
        <Route path="/" component={Screens.Login} />
      </Switch>
      {htmlENV}
      <InfoModal />
    </>
  );
}

export default App;
